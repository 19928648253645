/**
Synapse - adaptive theme
.col-xs- <544px Extra small
.col-sm- ≥544px Small
.col-md- ≥768px Medium
.col-lg- ≥992px Large
.col-xl- ≥1200px Extra large
Gutter width	1.875rem / 30px (15px on each side of a column)
*/

/** Map */
#map {
  position: absolute;
  height: 100%;
  width: 100%;
}
.synmap {
  min-height: 200px;
}


/** Прикреплённая галерея */
.node .field--name-field-gallery {}
.node .field--name-field-gallery:after {
  content: "";
  display: table;
  clear: both;
}
.node .field--name-field-gallery .field__item {
  float: left;
  margin: 0 15px 15px 0;
}
